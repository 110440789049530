import { Component, Input } from '@angular/core'
import { trigger, style, animate, transition } from '@angular/animations'
import { Customer } from '@boa/booster-types'
import { User as AccessControlUserData } from '@boosterfuels/access-control'

@Component({
  selector: 'user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('250ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
  standalone: false,
})
export class UserProfileModalComponent {
  @Input() user: Customer
  @Input() accessControlUserData: AccessControlUserData
  @Input() options: { title: string }

  public get initials(): string {
    return `
      ${this.user.firstName.substr(0, 1)}${this.user.lastName.substr(0, 1)}
    `
  }

  public get fullName(): string {
    return `${this.user.firstName} ${this.user.lastName}`
  }

  public get roles(): string {
    return this.user.roles.sort().join(', ')
  }

  public get permissions(): Record<string, string[]> {
    const mapped = (this.accessControlUserData.permissions || []).reduce(
      (acc, value) => {
        const [namespace, permission] = value.split('::')
        if (acc[namespace]) {
          acc[namespace] = [...acc[namespace], permission].sort()
        } else {
          acc[namespace] = [permission]
        }
        return acc
      },
      {} as Record<string, string[]>
    )
    return mapped
  }
}
