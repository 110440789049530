import { NavigationEnd, Router } from '@angular/router'
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@boa/app/app.store'
import type { Observable, Subscription } from 'rxjs'
import { UserService } from '@boa/app/_shared/services/user.service'
import { User as AccessControlUserData } from '@boosterfuels/access-control'
import { BreakpointObserver } from '@angular/cdk/layout'

@Component({
  selector: 'boa-layout',
  template: `
    <div class="app-loader" *ngIf="isAppLoading$ | async">Loading...</div>

    <boa-header
      [accessControlUserData]="accessControlUserData$ | async"
      [isAnimated]="isAnimated"
      [isNavigationVisible]="isNavigationVisible"
      [isNavigationExpanded]="isNavigationExpanded"
      (setNavigationVisibility)="onSetNavigationVisibility()"
    />

    <boa-navigation
      *ngIf="(isAppLoading$ | async) === false"
      [isAnimated]="isAnimated"
      [isNavigationVisible]="isNavigationVisible"
      (setNavigationVisibility)="onSetNavigationVisibility()"
      (setNavigationState)="onSetNavigationState($event)"
    />

    <div
      class="page__content"
      [class.animated]="isAnimated"
      [ngClass]="{
        'page__content--nav-expanded': isNavigationExpanded,
        'page__content--no-scroll': isScrollHidden,
        'page__content--padding-0': isPaddingRemoved,
      }"
    >
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LayoutComponent implements OnDestroy {
  public isNavigationExpanded: boolean
  public isNavigationVisible: boolean
  public isScrollHidden: boolean
  public isPaddingRemoved: boolean
  public isAppLoading$: Observable<boolean>
  public accessControlUserData$: Observable<AccessControlUserData>
  public isAnimated: boolean

  private routerSubscription: Subscription

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isNavigationVisible = false
    this.isScrollHidden = false
    this.isPaddingRemoved = false
    this.isNavigationExpanded = this.userService.getNavigationState()
    setTimeout(() => (this.isAnimated = true), 500)
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pageContent = document.querySelector('.page__content')
        if (pageContent) {
          pageContent.scrollTop = 0
        }

        if (event.url) {
          const [, moduleName, moduleChild] = event.url.split('/')

          this.isScrollHidden = ['cronus'].includes(moduleName)
          this.isPaddingRemoved = this.getIsPaddingRemoved(
            moduleName,
            moduleChild
          )
        }
      }
    })

    this.isAppLoading$ = this.store.pipe(select((s) => s.user.isAppLoading))
    this.accessControlUserData$ = this.store.pipe(
      select((s) => s.user.accessControlUserData)
    )
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe()
  }

  onSetNavigationState(isNavigationExpanded: boolean): void {
    this.isNavigationExpanded = isNavigationExpanded
  }

  onSetNavigationVisibility(): void {
    const isMobileSidebarShown =
      this.breakpointObserver.isMatched('(max-width: 991px)')

    if (isMobileSidebarShown) {
      this.isNavigationVisible = !this.isNavigationVisible
    }
  }

  getIsPaddingRemoved(moduleName: string, moduleChild: string): boolean {
    const modulesWithNoPadding = [
      'fleet-driver-assignments',
      'pricing-simulators',
      'unassigned-fuel-requests',
      'inventory-outflow',
    ]

    const isModuleWithoutIndex = this.isModuleWithoutIndexFn(
      moduleName,
      moduleChild
    )
    const isModuleWithExceptions = this.isModuleWithExceptionsFn(
      moduleName,
      moduleChild
    )

    return (
      // Whole module, including index and all children routes
      modulesWithNoPadding.includes(moduleName) ||
      // Only children, index route stays the same
      isModuleWithoutIndex('territories') ||
      isModuleWithoutIndex('loading-strategies') ||
      // Only children, except some
      isModuleWithExceptions('fleet-accounts', ['add']) ||
      isModuleWithExceptions('fleet-companies', ['add']) ||
      isModuleWithExceptions('fleet-issues', ['add']) ||
      isModuleWithExceptions('booster-service-providers', ['add']) ||
      isModuleWithExceptions('suppliers', ['add'])
    )
  }

  private isModuleWithoutIndexFn(moduleName: string, moduleChild: string) {
    return function (expectedModuleName: string) {
      return moduleName === expectedModuleName && !!moduleChild
    }
  }

  private isModuleWithExceptionsFn(moduleName: string, moduleChild: string) {
    return function (expectedModuleName: string, exceptions: string[]) {
      const [childUrl] = (moduleChild || '').split('?')

      return (
        moduleName === expectedModuleName &&
        !!moduleChild &&
        !exceptions.includes(childUrl)
      )
    }
  }
}
