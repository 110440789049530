<div class="modal-header">
  <h4 class="modal-title">{{ options.title }}</h4>
</div>
<div class="modal-body">
  <div class="d-flex align-items-center">
    <div
      class="initials rounded-circle d-flex justify-content-center text-center align-items-center me-3"
    >
      {{ initials }}
    </div>
    <span>
      <h3 class="mb-1">
        <b>{{ fullName }}</b>
      </h3>
      <h4 class="mb-0">{{ user.email }}</h4>
    </span>
  </div>
  <div class="mt-4">
    <b>Roles</b>
    <div>{{ roles }}</div>
  </div>
  <div class="mt-4">
    <b class="mb-2">Permissions</b>
    <div class="row">
      <ng-container *ngFor="let permission of permissions | keyvalue">
        <div class="col-sm-12 col-md-6 col-lg-3 text-truncate">
          <b>{{ permission.key }}:</b>
          <ul class="list-unstyled">
            <li *ngFor="let p of permission.value">
              {{ p === '*' ? 'EVERYTHING' : p }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mt-4">
    <b>Role attributes</b>
    <div class="mt-2" *ngFor="let role of user.roleAttributes; let i = index">
      <div class="d-flex">
        <div class="me-2">{{ i + 1 }}.</div>
        <span>
          <p class="mb-0">{{ role.roleId }}</p>
          <ng-container *ngIf="role?.regionIds">
            <p class="mb-0">Region IDs:</p>
            <a
              *ngFor="let regionId of role.regionIds"
              href
              [routerLink]="['/regions', regionId]"
              target="_blank"
              class="me-2"
            >
              {{ regionId }}
            </a>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
