import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { InlineSVGModule } from 'ng-inline-svg-2'

import { AlertComponent } from './alert/alert.component'
import { BounceLoaderComponent } from './bounce-loader/bounce-loader.component'
import { layoutComponents } from './layout/index'

import { PermissionsModule } from '@boa/app/_shared/modules/permissions-helpers'
import { SharedDirectivesModule } from '@boa/app/_shared/directives'
import { NavigationTypeaheadComponent } from './navigation-typeahead/navigation-typeahead.component'
import { SvgIconComponent } from './svg-icon/svg-icon.component'

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    NgbModule,
    RouterModule,
    PermissionsModule,
    SharedDirectivesModule,
    FormsModule,
    SvgIconComponent,
  ],
  declarations: [
    AlertComponent,
    NavigationTypeaheadComponent,
    BounceLoaderComponent,
    ...layoutComponents,
  ],
  exports: [
    AlertComponent,
    BounceLoaderComponent,
    SvgIconComponent,
    ...layoutComponents,
  ],
  providers: [],
})
export class MainComponentsModule {}
