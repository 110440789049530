<nav
  class="navigation hidden-print d-flex flex-column"
  [ngClass]="isNavigationExpanded ? 'expanded' : 'narrow'"
  [class.animated]="isAnimated"
  [class.active]="isNavigationVisible"
>
  <div class="navigation__header">
    <nav-logo
      [isNavigationExpanded]="isNavigationExpanded"
      [appVersion]="appVersion"
      (setNavigationState)="onSetNavigationState()"
      (click)="onSetActiveSection('')"
    />
  </div>

  <ul class="navigation__list">
    @for (parentNav of navigationList; track $index) {
      <nav-toggle
        [iconPath]="parentNav.iconPath"
        [listLabel]="parentNav.label"
        [activeSectionLabel]="activeSectionLabel()"
        (setActiveSection)="onSetActiveSection($event)"
      >
        @for (childNav of parentNav.children; track $index) {
          <nav-link
            *permissionIf="childNav.permission"
            [label]="childNav.label"
            [externalLink]="childNav.externalLink"
            [link]="childNav.link"
            [iconPath]="childNav.iconPath"
            (click)="onNavItemClick()"
          />
        }
      </nav-toggle>
    }

    <logout-link [user]="user" (logout)="onLogout()" />
  </ul>
  <div
    class="navigation__footer"
    [class.animated]="isAnimated"
    [class.active]="isNavigationVisible"
  >
    <span class="navigation__footer__version">{{ appVersion }}</span>
    @if (showEnvName) {
      <br />
      <span class="navigation__footer__environment">
        {{ envName }}
      </span>
    }
  </div>
</nav>
