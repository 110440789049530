import {
  Component,
  ChangeDetectionStrategy,
  computed,
  input,
  output,
} from '@angular/core'
import ACComponent from '@boa/app/_shared/helpers/ac-component'

@Component({
  selector: 'nav-toggle',
  template: `
    <li [class.nav-toggle]="true" [class.active]="isSectionActive()">
      <div class="d-flex" (click)="onToggleSection()">
        <div class="nav-toggle__icon">
          <img [src]="iconPath()" [alt]="listLabel()" />
        </div>
        <div
          class="flex-grow-1 d-flex align-items-center justify-content-between pe-4 gap-4"
        >
          <span class="nav-toggle__label">
            {{ listLabel() }}
          </span>
          <i class="nav-toggle__caret fa fa-angle-down"></i>
        </div>
      </div>
      <ul attr.aria-labelledby="{{ listLabel() }}" class="nav-toggle__sublist">
        <ng-container>
          <ng-container *ngTemplateOutlet="templateOutlet" />
        </ng-container>
      </ul>
    </li>
    <ng-template #templateOutlet>
      <ng-content />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NavToggleComponent extends ACComponent {
  iconPath = input.required<string>()
  listLabel = input.required<string>()
  activeSectionLabel = input.required<string>()

  setActiveSection = output<string>()

  isSectionActive = computed<boolean>(() => {
    return this.activeSectionLabel() === this.listLabel()
  })

  onToggleSection(): void {
    this.setActiveSection.emit(this.isSectionActive() ? '' : this.listLabel())
  }
}
