import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ComponentOutletDirective } from './component-outlet.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [ComponentOutletDirective],
  exports: [ComponentOutletDirective],
})
export class SharedDirectivesModule {}
