import {
  Component,
  Input,
  HostBinding,
  signal,
  inject,
  output,
  ChangeDetectionStrategy,
  type OnInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { type AppState } from '@boa/app/app.store'
import * as UserActions from '@boa/app/_shared/reducers/user/user.actions'
import { UserService } from '@boa/app/_shared/services/user.service'
import type { User } from '@boa/app/_shared/types/user.model'
import ACComponent from '@boa/app/_shared/helpers/ac-component'
import { environment as ENV } from '@boa/app/../environments/environment'
import { NAVIGATION_LIST_TOKEN } from '@boa/app/_shared/navigation-lists/navigation-list.token'

const {
  version: appVersion,
} = require('../../../../../../../../../package.json')

@Component({
  selector: 'boa-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NavigationComponent extends ACComponent implements OnInit {
  #router = inject(Router)
  navigationList = inject(NAVIGATION_LIST_TOKEN)

  activeSectionLabel = signal<string>(this.#findActiveLabel())

  @HostBinding('class') hostClass

  public isNavigationExpanded: boolean
  public user: User
  public appVersion: string
  public envName: string
  public showEnvName = false

  @Input() isAnimated: boolean
  @Input() isNavigationVisible: boolean

  setNavigationVisibility = output()
  setNavigationState = output<boolean>()

  constructor(
    private userService: UserService,
    private store: Store<AppState>
  ) {
    super()
    this.isNavigationExpanded = this.userService.getNavigationState()
    this.#setHostClass(this.isNavigationExpanded)
    this.appVersion = appVersion
    this.envName = ENV.envName
    this.showEnvName = !['production', 'saas-production', 'saas'].includes(
      this.envName
    )
  }

  ngOnInit() {
    this.user = this.userService.getUser()
  }

  #findActiveLabel(): string {
    return (
      this.navigationList.find((listItem) =>
        listItem.children.find((child) => {
          if ('link' in child) {
            return this.#router.url.includes(child.link)
          }
          return false
        })
      )?.label || ''
    )
  }

  #setHostClass(isNavExpanded: boolean): void {
    this.hostClass = isNavExpanded ? 'expanded' : ''
  }

  onLogout(): void {
    this.store.dispatch(new UserActions.LogoutAction())
  }

  onSetNavigationState(): void {
    this.isNavigationExpanded = !this.isNavigationExpanded
    this.#setHostClass(this.isNavigationExpanded)
    this.userService.setNavigationState(this.isNavigationExpanded)
    this.setNavigationState.emit(this.isNavigationExpanded)
  }

  onSetActiveSection(sectionLabel: string): void {
    this.activeSectionLabel.set(sectionLabel)
  }

  onNavItemClick() {
    this.setNavigationVisibility.emit()
  }
}
