<div class="d-grid align-items-center input-container">
  <i class="fa fa-search text-black-50 text-center"></i>
  <input
    #input
    type="text"
    class="form-control"
    placeholder="Type to navigate..."
    [(ngModel)]="model"
    [ngbTypeahead]="onInput"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    [resultTemplate]="navigationItems"
    (focus)="onFocus()"
    (selectItem)="onSelectItem($event)"
  />

  <kbd class="text-black-50"></kbd>

  <ng-template #navigationItems let-navItem="result" let-t="term">
    @if (navItem.groupBy) {
      <p [attr.data-groupBy]="navItem.groupBy" class="text-uppercase m-0">
        {{ navItem.groupBy }}
      </p>
    }
    <ngb-highlight [result]="navItem.label | titlecase" [term]="t" />
    <ngb-highlight
      [result]="navItem.link ? '/' + navItem.link : navItem.externalLink"
      [term]="t"
    />
  </ng-template>
</div>
