import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject,
} from '@angular/core'
import { TitleCasePipe } from '@angular/common'
import { Router } from '@angular/router'
import { merge, Subject, type Observable, type OperatorFunction } from 'rxjs'
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators'
import { NAVIGATION_LIST_TOKEN } from '@boa/app/_shared/navigation-lists/navigation-list.token'
import type { ChildLink } from '@boa/app/_shared/navigation-lists/navigation-list.types'

const MAX_ITEMS = 8 as const

type ChildLinkWithGroupBy = { groupBy: string } & ChildLink

@Component({
  selector: 'navigation-typeahead',
  templateUrl: 'navigation-typeahead.component.html',
  styleUrls: ['./navigation-typeahead.component.scss'],
  standalone: false,
})
export class NavigationTypeaheadComponent {
  #router = inject(Router)
  #navigationList: ChildLinkWithGroupBy[] = inject(NAVIGATION_LIST_TOKEN)
    .map((listItem) =>
      listItem.children.map((child, index) => ({
        ...child,
        groupBy: index === 0 ? listItem.label : '',
      }))
    )
    .flat()

  public focus$ = new Subject<string>()
  public model: string

  @ViewChild('input', { static: true }) input: ElementRef

  @HostListener('window:keyup', ['$event']) onKeyUp(
    event: KeyboardEvent
  ): void {
    if (event.altKey && event.code === 'KeyN') {
      this.input.nativeElement.focus()
    }
  }

  #getFilteredNavigationItems(
    navigationList: ChildLinkWithGroupBy[],
    term = ''
  ): ChildLinkWithGroupBy[] {
    if (!term || typeof term !== 'string') {
      return navigationList.slice(0, MAX_ITEMS)
    }

    return navigationList
      .filter((navItem) => {
        const link = 'link' in navItem ? navItem.link : navItem.externalLink
        const text = link.replaceAll('-', ' ') + navItem.label
        return text.toLowerCase().includes(term.toLowerCase())
      })
      .slice(0, MAX_ITEMS)
  }

  public formatter = (navItem): string =>
    new TitleCasePipe().transform(navItem.label)

  public onInput: OperatorFunction<string, any[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged())

    return merge(debouncedText$, this.focus$).pipe(
      map((term = '') =>
        this.#getFilteredNavigationItems(this.#navigationList, term)
      )
    )
  }

  public onSelectItem({ item }): void {
    this.model = undefined

    if ('externalLink' in item) {
      window.open(item.externalLink, '_blank')
    } else {
      this.#router.navigate([`/${item.link}`])
    }
  }

  public onFocus(): void {
    this.focus$.next(this.model)
  }
}
