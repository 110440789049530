import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Injector,
  Input,
  NgModuleFactory,
  NgModuleRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Type,
  ViewContainerRef,
} from '@angular/core'

@Directive({
  selector: '[componentOutlet]',
  standalone: false,
})
export class ComponentOutletDirective implements OnChanges, OnDestroy {
  @Input() componentOutlet: Type<any>
  @Input() componentOutletInjector: Injector
  @Input() componentOutletInputs: any = {}
  @Input() componentOutletContent: any[][]
  @Input() componentOutletNgModuleFactory: NgModuleFactory<any>

  private _componentRef: ComponentRef<any> | null = null
  private _moduleRef: NgModuleRef<any> | null = null

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._viewContainerRef.clear()
    this._componentRef = null

    if (this.componentOutlet) {
      const elInjector =
        this.componentOutletInjector || this._viewContainerRef.parentInjector

      if (changes['ngComponentOutletNgModuleFactory']) {
        if (this._moduleRef) this._moduleRef.destroy()

        if (this.componentOutletNgModuleFactory) {
          const parentModule = elInjector.get(NgModuleRef)
          this._moduleRef = this.componentOutletNgModuleFactory.create(
            parentModule.injector
          )
        } else {
          this._moduleRef = null
        }
      }

      const componentFactoryResolver = this._moduleRef
        ? this._moduleRef.componentFactoryResolver
        : elInjector.get(ComponentFactoryResolver)

      const componentFactory = componentFactoryResolver.resolveComponentFactory(
        this.componentOutlet
      )

      this._componentRef = this._viewContainerRef.createComponent(
        componentFactory,
        this._viewContainerRef.length,
        elInjector,
        this.componentOutletContent
      )

      this.applyInputs(this._componentRef.instance)
    }
  }

  ngOnDestroy(): void {
    if (this._moduleRef) {
      this._moduleRef.destroy()
    }
  }

  private applyInputs(instance: Type<any>) {
    Object.entries(this.componentOutletInputs).forEach(([key, value]) => {
      instance[key] = value
    })
  }
}
