import { Component, Input, Output, EventEmitter } from '@angular/core'
import { ModalService } from '@boa/app/_shared/services/modal-service.service'
import { UserProfileModalComponent } from './user-profile-modal/user-profile-modal.component'
import { User as AccessControlUserData } from '@boosterfuels/access-control'

@Component({
  selector: 'header-user-dropdown',
  template: `
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
      <div
        class="header__action-dropdown-link user"
        id="userDropdown"
        ngbDropdownToggle
      >
        <span><i class="user__icon fa fa-user-circle-o"></i></span>
        <span class="user__name">{{ user.firstName }} {{ user.lastName }}</span>
      </div>
      <ul
        ngbDropdownMenu
        class="header__action-dropdown-menu"
        aria-labelledby="userDropdown"
      >
        <li>
          <button
            type="button"
            ngbDropdownItem
            class="dropdown-item"
            (click)="showUserProfile()"
          >
            My profile
          </button>
        </li>
        <li>
          <button
            type="button"
            ngbDropdownItem
            class="dropdown-item"
            (click)="logout.emit()"
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  `,
  standalone: false,
})
export class HeaderUserDropdownComponent {
  @Input() user
  @Input() accessControlUserData: AccessControlUserData
  @Output() logout: EventEmitter<void> = new EventEmitter()

  constructor(private modalService: ModalService) {}

  public showUserProfile(): void {
    const modalRef = this.modalService.create({
      component: UserProfileModalComponent,
      options: { windowClass: 'user-profile' },
      data: {
        user: this.user,
        accessControlUserData: this.accessControlUserData,
        options: { title: 'My Profile' },
      },
    })

    modalRef.then(
      () => null,
      () => null
    )
  }
}
