import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'nav-link',
  template: `
    <li [ngClass]="{ active: isActive() }">
      <a
        class="nav-link"
        routerLinkActive="active"
        *ngIf="routeLink"
        [routerLink]="routeLink"
      >
        <div class="nav-link__icon">
          <img [src]="iconPath" [alt]="label" />
        </div>
        <span class="nav-link__label">{{ label }}</span>
      </a>
      <a
        class="nav-link"
        routerLinkActive="active"
        *ngIf="externalLink"
        target="_blank"
        [href]="externalLink"
      >
        <div class="nav-link__icon">
          <img [src]="iconPath" [alt]="label" />
        </div>
        <span class="nav-link__label">{{ label }}</span>
      </a>
    </li>
  `,
  standalone: false,
})
export class NavLinkComponent {
  public routeLink: string

  @Input() label: string
  @Input() set link(value: string | undefined) {
    if (value) {
      this.routeLink = `/${value.toLowerCase()}`
    }
  }
  @Input() externalLink: string
  @Input() iconPath: string
  @Input() hideOnWideScreen: boolean
  @Input() tooltipPlacement = 'right'

  constructor(private router: Router) {}

  isActive() {
    return this.router.url.endsWith(this.link)
  }
}
