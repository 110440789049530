import { Component, Input, OnInit } from '@angular/core'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { Observable } from 'rxjs'

import { AlertService } from '../../../services/alert.service'
import { AlertInterface } from '../../../types/alert.interface'

type MessageType = 'string' | 'component'

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('*', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 0 }), animate(100)]),
      transition('* => void', [animate(100, style({ opacity: 0 }))]),
    ]),
  ],
  standalone: false,
})
export class AlertComponent implements OnInit {
  @Input() alerts$: Observable<AlertInterface[]>

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alerts$ = this.alertService.alertsChanged$
  }

  closeAlert(index: number): void {
    this.alertService.closeAlert(index)
  }

  getMessageType(message: AlertInterface['message']): MessageType {
    if (typeof message === 'string') {
      return 'string'
    }

    return 'component'
  }
}
