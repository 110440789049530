import { Router } from '@angular/router'
import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@boa/app/app.store'

import { PageTitleService } from '@boa/app/_shared/services/page-title.service'
import { UserService } from '@boa/app/_shared/services/user.service'

import * as UserActions from '@boa/app/_shared/reducers/user/user.actions'

import { User } from '@boa/app/_shared/types/user.model'
import { User as AccessControlUserData } from '@boosterfuels/access-control'
import ACComponent from '../../../../helpers/ac-component'

@Component({
  selector: 'boa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent extends ACComponent implements OnInit, OnDestroy {
  public pageTitle: any
  public user: User

  public regions$

  private pageTitleSubscription

  @Input() accessControlUserData: AccessControlUserData
  @Input() isAnimated: boolean
  @Input() isNavigationVisible: boolean
  @Input() isNavigationExpanded: boolean

  @Output() setNavigationVisibility: EventEmitter<boolean> = new EventEmitter()

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private store: Store<AppState>,
    private router: Router
  ) {
    super()

    this.regions$ = this.store.pipe(select((s) => s.user.regions))
  }

  ngOnInit(): void {
    this.pageTitleSubscription = this.pageTitleService
      .getTitle()
      .subscribe((x) => {
        this.pageTitle = x
      })

    this.user = this.userService.getUser()
  }

  ngOnDestroy(): void {
    if (this.pageTitleSubscription) {
      this.pageTitleSubscription.unsubscribe()
    }
  }

  logout(): void {
    this.store.dispatch(new UserActions.LogoutAction())
  }

  toggleNavigation(): void {
    this.setNavigationVisibility.emit()
  }
}
