<header class="header">
  <div
    class="header__container"
    [class.animated]="isAnimated"
    [class.nav-expanded]="isNavigationExpanded"
  >
    <div class="container-fluid">
      <div
        class="d-grid align-items-center header__actions-wrapper"
        [class]="{ 'd-grid--saas': isSaas }"
      >
        <div class="header__page-info">
          <div
            class="header__hamburger-container"
            [class.animated]="isAnimated"
            [class.active]="isNavigationVisible"
            (click)="toggleNavigation()"
          >
            <button
              type="button"
              class="hamburger hamburger--arrowturn"
              [class.active]="isNavigationVisible"
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div
            class="header__title-container"
            [class.active]="isNavigationVisible"
          >
            <p
              class="header__active-page-title"
              [ngClass]="{ 'with-subtitle': pageTitle?.subtitle !== '' }"
            >
              {{ pageTitle?.title }}
            </p>
            <p class="header__active-page-subtitle">
              {{ pageTitle?.subtitle }}

              <span *ngIf="pageTitle?.isHypercare" class="hypercare-label">
                <i class="fa fa-exclamation-triangle"></i> Hypercare
              </span>
            </p>
          </div>
        </div>

        @if (!isSaas) {
          <navigation-typeahead class="header__typeahead" />
        }

        <div class="d-none d-md-block header__actions">
          <header-user-dropdown
            [user]="user"
            [accessControlUserData]="accessControlUserData"
            (logout)="logout()"
          >
          </header-user-dropdown>
        </div>
      </div>
    </div>
  </div>
  <header></header>
</header>
