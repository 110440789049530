import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'logout-link',
  template: `
    <li class="d-md-none">
      <a href class="logout-link" (click)="onLogout($event)">
        <div class="logout-link__icon">
          <img src="/assets/icons/navigation/log-out.svg" alt="Logout" />
        </div>
        <span class="logout-link__label">
          Logout, {{ user.firstName }} {{ user.lastName }}
        </span>
      </a>
    </li>
  `,
  styles: [
    `
      li a {
        color: rgba(245, 245, 245, 0.8);
        text-decoration: none !important;
        padding: 18px 22px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
      }
    `,
  ],
  standalone: false,
})
export class LogoutLinkComponent {
  @Input() user

  @Output() logout: EventEmitter<any> = new EventEmitter()

  onLogout(e) {
    e.preventDefault()
    this.logout.emit()
  }
}
