<div class="alert-cont">
  <div *ngFor="let alert of alerts$ | async; let i = index" @fadeInOut>
    <ngb-alert [type]="alert.type" (closed)="closeAlert(i)">
      <ng-container [ngSwitch]="getMessageType(alert.message)">
        <ng-container *ngSwitchCase="'string'">
          <div class="me-5" [innerHTML]="alert.message"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'component'">
          <div class="me-5">
            <ng-container
              *componentOutlet="alert.message; inputs: alert.data"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ngb-alert>
  </div>
</div>
