import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'nav-logo',
  template: `
    <div class="nav-logo">
      <button
        type="button"
        class="hamburger"
        (click)="setNavigationState.emit()"
      >
        <i
          [ngClass]="isNavigationExpanded ? 'fa fa-outdent' : 'fa fa-list'"
        ></i>
      </button>
      <a routerLink="/home" routerLinkActive="is-active">
        <img src="/assets/icons/navigation/BOA_full.png" alt="BOA logo" />
      </a>
      <strong>{{ appVersion }}</strong>
    </div>
  `,
  styleUrls: ['./nav-logo.scss'],
  standalone: false,
})
export class NavLogoComponent {
  @Input() isNavigationExpanded: boolean
  @Input() appVersion: string

  @Output() setNavigationState: EventEmitter<any> = new EventEmitter()
}
